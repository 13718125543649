import React from "react";

const Footer = () => {
    return (
        <footer style={{
            position: 'relative',
            bottom: 0,
            zIndex: 99,
            width: '100%',
            padding: '1rem 0 1rem 0',
            backgroundColor: 'rgba(16, 15, 28, 1)', // Solid background
            color: '#ffffff',
            textAlign: 'center',
            borderTop: '1px solid rgba(255, 255, 255, 0.1)', // Optional border
            fontFamily: '"Inter", system-ui, sans-serif',
            }}>
            <p>
                Call or Text us: 
                <a href="tel:+19083071999" style={{
                color: '#DC66FF',
                textDecoration: 'none',
                marginLeft: '0.5rem',
                }}>+1 (908) 307-1999</a>
            </p>
            <p>
                Email us: <a href="mailto:doquantumresearch@gmail.com" style={{
                color: '#DC66FF',
                textDecoration: 'none',
                cursor: 'pointer',
                }}>doquantumresearch@gmail.com</a>
            </p>
            
            {/* Social Media Section */}
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '1rem', // Space between items
                marginTop: '0.5rem'
            }}>
                <span style={{ fontSize: '1rem', fontWeight: 'bold' }}>Join Us:</span>

                {/* Discord Button */}
                <a
                    href="https://discord.gg/ZFkshMdegh"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ display: 'inline-block' }}
                >
                    <img
                        src="/discord-color-logo-free-png-images-1536x1536.png" // Replace with actual image path
                        alt="Discord"
                        style={{
                            width: '28px', // Adjusted icon size
                            height: '28px',
                            filter: 'brightness(1)',
                            transition: 'opacity 0.3s',
                        }}
                        onMouseOver={(e) => e.currentTarget.style.opacity = 0.7}
                        onMouseOut={(e) => e.currentTarget.style.opacity = 1}
                    />
                </a>

                {/* Instagram Button */}
                <a
                    href="https://www.instagram.com/doquantum/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ display: 'inline-block' }}
                >
                    <img
                        src="/instagram-logo-eps-png-instagram-logo-1784.png" // Replace with actual image path
                        alt="Instagram"
                        style={{
                            width: '28px',
                            height: '28px',
                            filter: 'brightness(1)',
                            transition: 'opacity 0.3s',
                        }}
                        onMouseOver={(e) => e.currentTarget.style.opacity = 0.7}
                        onMouseOut={(e) => e.currentTarget.style.opacity = 1}
                    />
                </a>
            </div>
            
            
        </footer>
    );
};

export default Footer;