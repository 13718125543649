import React from 'react';

const Header = () => {
  return (
    <header
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '5rem',
        backgroundColor: 'rgba(16, 15, 28, 0.9)', // Semi-transparent background
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        zIndex: 99, // Ensure it's above other elements
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Optional shadow for separation
        padding: '0 1.5rem',
        boxSizing: 'border-box',
      }}
    >
      {/* Logo / Home Link */}
      <a
        href="/"
        style={{
          display: 'flex',
          alignItems: 'center',
          textDecoration: 'none',
        }}
      >
        <img
          src="/FullLogo_Transparent_NoBuffer.png"
          alt="Do Quantum Logo"
          style={{
            height: '3.5rem',
            cursor: 'pointer',
          }}
        />
      </a>

      {/* Discord Button (Full Button on Large Screens, Icon Only on Small Screens) */}
      <a
        href="https://discord.gg/ZFkshMdegh"
        target="_blank"
        rel="noopener noreferrer"
        className="discord-button"
      >
        <img
          src="/discord-color-logo-free-png-images-1536x1536.png" // Replace with actual Discord icon path
          alt="Discord"
          className="discord-icon"
        />
        <span className="discord-text">Join Discord</span>
      </a>

      {/* Responsive Styling */}
      <style>
        {`
          .discord-button {
            display: flex;
            align-items: center;
            padding: 0.6rem 1.2rem;
            background-color: #5865F2; /* Discord brand color */
            color: #ffffff;
            border-radius: 25px;
            font-weight: 600;
            text-decoration: none;
            box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
            transition: background-color 0.3s ease;
          }

          .discord-button:hover {
            background-color: #4e5adc;
          }

          .discord-icon {
            width: 1.5rem;
            height: 1.5rem;
            margin-right: 0.5rem;
          }

          .discord-text {
            display: inline-block;
          }

          /* Hide button text on small screens */
          @media (max-width: 480px) {
            .discord-button {
              padding: 0rem;
              background-color: transparent; /* Remove background */
              box-shadow: none;
              border-radius: 50%; /* Make it circular */
              width: 3rem; /* Make the icon a bit bigger */
              height: 3rem;
            }

            .discord-button:hover {
              background-color: #4e5adc;
            }

            .discord-icon {
              width: 3.5rem; /* Make the icon a bit bigger */
              height: 3.5rem;
              margin-right: 0; /* Remove spacing */
              border-radius: 50%; /* Make it circular */
            }

            .discord-text {
              display: none; /* Hide text */
            }
          }
        `}
      </style>
    </header>
  );
};

export default Header;
